// Blog_Open_Page
import React, { useContext, useState } from 'react';
import { motion } from 'framer-motion';
import { Global } from '../../../App';
import Footer from '../../Footer/Footer';
import Navbar from '../../Navbar/Navbar';
import Datas from "../../../Json_Files/Blog_Page.json";
import { useNavigate, Link } from 'react-router-dom';

const Blog_Open_Page = () => {

    let { Selected_Blogs, Loader, Categorys } = useContext(Global);
    const [Selected_Blog, setSelected_Blog] = Selected_Blogs;
    // console.log(Selected_Blog);

    let Navigate = useNavigate();
    const [Category, setCategory] = Categorys;
    let [IsLoading, setIsLoading] = Loader;

    return (
        <>
            <Navbar></Navbar>
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.8 }}
                className="min-h-screen bg-gray-900 text-white"
            >
                {/* Hero Section */}
                <motion.section
                    initial={{ y: 50, opacity: 0 }}
                    whileInView={{ y: 0, opacity: 1 }}
                    transition={{ duration: 0.8 }}
                    className="py-10 mt-10 bg-gray-900"
                >
                    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                        {/* Title */}
                        <motion.h1
                            initial={{ y: 50, opacity: 0 }}
                            whileInView={{ y: 0, opacity: 1 }}
                            transition={{ duration: 0.6, delay: 0.2 }}
                            className="text-green-300 text-4xl sm:text-6xl font-bold mb-6"
                        >
                            {Selected_Blog.Title}
                        </motion.h1>

                        {/* Meta Info Card */}
                        <motion.div
                            initial={{ opacity: 0, y: 30 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.6, delay: 0.4 }}
                            className="rounded-3xl sm:p-5 mb-6"

                        >
                            <div className=" text-gray-400">
                                <div className="flex items-center space-x-2">
                                    <i className="bi bi-calendar text-xl text-green-500"></i>
                                    <p>{Selected_Blog.Date}</p>
                                </div>
                                <div className="flex items-center space-x-2 mt-3">
                                    <i className="bi bi-person-circle text-xl text-blue-500"></i>
                                    <p>By {Selected_Blog.Author}</p>
                                </div>
                            </div>
                        </motion.div>

                        {/* Featured Image */}
                        <motion.img
                            src={Selected_Blog.Image}
                            alt={Selected_Blog.Title}
                            className="w-full h-[570px] object-cover rounded-3xl shadow-lg border-4 border-gray-800"
                        />


                    </div>
                </motion.section>

                {/* Content Section */}
                <section className=" bg-gray-900">
                    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                        {/* Main Paragraphs */}
                        <motion.div
                            initial={{ opacity: 0, y: 30 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.6, delay: 0.4 }}
                            className="p-6 bg-gray-800 rounded-lg shadow-lg"
                        >
                            {Selected_Blog.Main_Paragraphs.map((paragraph, index) => (
                                <motion.div
                                    key={index}
                                    initial={{ opacity: 0, y: 50 }}
                                    whileInView={{ opacity: 1, y: 0 }}
                                    viewport={{ once: true }}
                                    transition={{ duration: 0.6, delay: index * 0.2 }}
                                    className="flex items-start space-x-4 mt-2"
                                >
                                    {/* Icon */}
                                    <div className="flex-shrink-0 text-[#22c55e]">
                                        <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M18.6667 9.2333V9.99997C18.6656 11.797 18.0838 13.5455 17.0078 14.9848C15.9318 16.4241 14.4194 17.477 12.6961 17.9866C10.9729 18.4961 9.13105 18.4349 7.44539 17.8121C5.75973 17.1894 4.32055 16.0384 3.34247 14.5309C2.36439 13.0233 1.89983 11.24 2.01806 9.4469C2.1363 7.65377 2.83101 5.94691 3.99857 4.58086C5.16613 3.21482 6.74399 2.26279 8.49683 1.86676C10.2497 1.47073 12.0836 1.65192 13.725 2.3833" stroke="#009035" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round">
                                            </path>
                                            <path d="M18.6666 3.33325L10.3333 11.6749L7.83325 9.17492" stroke="#009035" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round">
                                            </path>
                                        </svg>
                                    </div>

                                    {/* Feature Text */}
                                    <div>
                                        <p className="text-lg">{paragraph}</p>
                                    </div>
                                </motion.div>
                            ))}
                        </motion.div>

                        {/* Key Points as Cards */}
                        <motion.div
                            initial="hidden"
                            whileInView="visible"
                            viewport={{ once: true }}
                            variants={{
                                hidden: { opacity: 0 },
                                visible: {
                                    opacity: 1,
                                    transition: {
                                        staggerChildren: 0.2
                                    }
                                }
                            }}
                            className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-8 mt-10"
                        >
                            {Selected_Blog.Key_Points_And_Descriptions.map((point) => (
                                <motion.div
                                    key={point.id}
                                    variants={{
                                        hidden: { opacity: 0, y: 30 },
                                        visible: { opacity: 1, y: 0 }
                                    }}
                                    transition={{ duration: 0.6 }}
                                    className="rounded-3xl p-6 sm:p-8 bg-gray-800 shadow-lg"
                                    // style={{ backgroundColor: `${Selected_Blog.Color}20` }}
                                    whileHover={{ scale: 1.03 }}
                                >
                                    <h3 className="text-xl font-semibold text-white mb-4">
                                        {point.Point}
                                    </h3>
                                    {point.Description.map((desc, index) => (
                                        // <p key={index} className="text-gray-400">
                                        //     {desc}
                                        // </p>
                                        <div className='flex text-lg items-start space-x-4 mt-2'>
                                            <div>-</div>
                                            <div>{desc}</div>
                                        </div>
                                    ))}
                                </motion.div>
                            ))}
                        </motion.div>
                    </div>
                </section>

                {/* Call to Action Section */}
                <motion.section
                    initial={{ opacity: 0, y: 50 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.6, delay: 0.4 }}
                    className="py-20  text-center"
                >
                    <div className="mx-auto px-4 sm:px-6 lg:px-8">
                        {/* CTA Card */}
                        <motion.div
                            className="rounded-3xl p-8 sm:p-12"
                        >
                            <h3 className="text-4xl sm:text-5xl font-bold mb-6">
                                {Datas.Blog_Bottom.Title}
                            </h3>
                            <p className="text-lg sm:text-xl text-gray-400 leading-relaxed mb-8">
                                {Datas.Blog_Bottom.Paragraph}
                            </p>
                            <motion.button
                                className="bg-gradient-to-r from-green-600 to-blue-600 text-white py-4 px-10 rounded-full font-semibold shadow-lg hover:shadow-2xl transition-all duration-300"
                                whileHover={{ scale: 1.05 }}
                                whileTap={{ scale: 0.95 }}
                                onClick={() => { Navigate("/Category"); window.scrollTo(0, 0); setCategory("All Products"); setIsLoading(true); }}
                            >
                                Explore Products
                            </motion.button>
                        </motion.div>
                    </div>
                </motion.section>


            </motion.div>
            <Footer></Footer>
        </>
    );
};

export default Blog_Open_Page;