import React,{useState} from 'react';
import { motion } from 'framer-motion';
import Datas from '../../../Json_Files/All_Section_Details.json'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation, Autoplay } from 'swiper/modules';

const Client_Review_Section = () => {


    let [Data,SetData] = useState(Datas.Client_Review_Section);
    

    return (
        <motion.section
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            transition={{ duration: 0.8 }}
            className="py-20 bg-gray-900 text-white"
            style={{ maxWidth: '1920px', margin: '0 auto' }}
        >
            {/* Section Title */}
            <div className="max-w-[1920px] mx-auto px-4 sm:px-6 lg:px-8 text-center">


                <motion.h2
                    initial={{ y: 50, opacity: 0 }}
                    whileInView={{ y: 0, opacity: 1 }}
                    transition={{ duration: 0.6 }}
                    className="text-center text-4xl sm:text-5xl font-bold text-white mb-16 relative"
                >
                    🌿 What Our Clients Say 🌿
                    <span className="block mt-4 h-1 bg-gradient-to-r from-green-500 to-blue-500 w-24 mx-auto rounded-full" />
                </motion.h2>
            </div>

            {/* Swiper Slider */}
            <div className="relative max-w-[1920px] py-10 mx-auto px-4 sm:px-6 lg:px-8">
                <Swiper
                    modules={[Navigation, Autoplay]}
                    navigation={{
                        prevEl: '.custom-prev',
                        nextEl: '.custom-next'
                    }}
                    autoplay={{ delay: 3000, disableOnInteraction: false }}
                    loop={true}
                    spaceBetween={20}
                    slidesPerView={1}
                    breakpoints={{
                        240: { slidesPerView: 1 },
                        480: { slidesPerView: 1 },
                        640: { slidesPerView: 2 },
                        768: { slidesPerView: 2 }, // Tablets
                        1024: { slidesPerView: 3 },
                        1280: { slidesPerView: 4 } // Laptops
                    }}
                    className="max-w-[1920px] mx-auto"
                >
                    {Data.Review_Data.map((review, index) => (
                        <SwiperSlide key={index}>
                            <motion.div
                                initial={{ opacity: 0, scale: 0.9 }}
                                whileInView={{ opacity: 1, scale: 1 }}
                                transition={{ duration: 0.6 }}
                                className="bg-gray-800 p-6 h-72 rounded-lg shadow-lg hover:shadow-xl transition-shadow"
                            >
                                {/* Avatar and Name */}
                                <div className="flex items-center mb-4">
                                    <img
                                        src={"https://res.cloudinary.com/dtnqlv91f/image/upload/v1742562384/General/ndlgl09kofes52u6xtm3.png"}
                                        alt={review.Name}
                                        className="w-12 h-12 rounded-full object-cover border-2 border-green-500"
                                    />
                                    <div className="ml-4">
                                        <h3 className="font-semibold text-white">{review.Name}</h3>
                                        <p className="text-sm text-gray-400">{review.Title}</p>
                                    </div>
                                </div>

                                {/* Review */}
                                <p className="text-gray-300 italic mb-4">"{review.Review}"</p>

                                {/* Rating */}
                                <div className="flex justify-start">
                                    {Array.from({ length: review.Rating }, (_, i) => (
                                        <i
                                            key={i}
                                            className="bi bi-star-fill text-yellow-500 text-lg"
                                        ></i>
                                    ))}
                                </div>
                            </motion.div>
                        </SwiperSlide>
                    ))}
                    <div className="flex justify-between items-center mt-4 font-extraboldc">
                    {/* Prev Button (Hidden on sm and xs screens) */}
                    <button className="custom-prev bg-green-700 hover:bg-green-600 font-extrabold text-3xl md:text-xl text-white px-4 py-2 rounded transition-colors">
                        ⟵
                    </button>

                    {/* Next Button (Hidden on sm and xs screens) */}
                    <button className="custom-next bg-green-700 hover:bg-green-600 font-extrabold text-3xl md:text-xl text-white px-4 py-2 rounded transition-colors">
                        ⟶
                    </button>

                </div>
                </Swiper>

                {/* Navigation Buttons */}
                
            </div>
        </motion.section>
    );
};

export default Client_Review_Section;