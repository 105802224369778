import React,{useState} from 'react';
import { motion } from 'framer-motion';
import Datas from '../../../Json_Files/Vision_Mission_Page.json'

const Why_Vision_Matters_Section = () => {

  let [Data,SetData] = useState(Datas.Vision_Page.Why_Vision_Matters)
  
  return (
    <motion.section
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      viewport={{ once: false }}
      transition={{ duration: 0.8 }}
      className="py-20 bg-gray-900"
    >
      {/* Section Title */}
      <div className="max-w-[1920px] mx-auto px-4 sm:px-6 lg:px-8">
        
        <motion.h2
          initial={{ y: 50, opacity: 0 }}
          whileInView={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.6 }}
          className="text-center text-4xl sm:text-5xl font-bold text-white mb-16 relative"
        >
          {Data.Title}
          <span className="block mt-4 h-1 bg-gradient-to-r from-green-500 to-blue-500 w-40 mx-auto rounded-full" />
        </motion.h2>

        {/* Introduction Paragraph */}
        <motion.div
          initial={{ opacity: 0, y: 30 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6, delay: 0.2 }}
          className="mb-20 text-center"
        >
          <p className="text-lg lg:mx-20 md:mx-auto sm:text-xl text-gray-400 leading-relaxed">
          {Data.Description}
          </p>
        </motion.div>

        {/* Statistics Grid */}
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: false }}
          variants={{
            hidden: { opacity: 0 },
            visible: {
              opacity: 1,
              transition: {
                staggerChildren: 0.2
              }
            }
          }}
          className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-20"
        >
          {Data.Stats.map((stat, index) => (
            <motion.div
            key={index}
            variants={{
                hidden: { y: 30, opacity: 0 },
                visible: { y: 0, opacity: 1 }
            }}
            transition={{ duration: 0.4 }}
              className="bg-gray-800/90 rounded-3xl p-6 sm:p-8 backdrop-blur-lg text-center"
              whileHover={{ scale: 1.05 }}
            >
              {/* Number Animation */}
              <motion.span
                className="text-5xl sm:text-6xl font-bold text-green-500 block mb-4"
                initial={{ scale: 0 }}
                animate={{ scale: 1 }}
                transition={{ duration: 0.8, delay: index * 0.2 }}
              >
                {stat.Number}
              </motion.span>

              {/* Label */}
              <h3 className="text-xl sm:text-2xl font-semibold text-white mb-3">
                {stat.Label}
              </h3>

              {/* Description */}
              <p className="text-gray-400">{stat.Description}</p>
            </motion.div>
          ))}
        </motion.div>

      </div>
    </motion.section>
  );
};

export default Why_Vision_Matters_Section;