// Blog_Section

import React, { useContext, useState } from 'react';
import { motion } from 'framer-motion';
import { Global } from '../../../App';
import Datas from "../../../Json_Files/Blog_Page.json";
import { useNavigate, Link } from 'react-router-dom';

const Blog_Section = () => {

    let Navigate = useNavigate();

    let { Selected_Blogs, Loader } = useContext(Global);
    const [Selected_Blog, setSelected_Blog] = Selected_Blogs;
    let [IsLoading, setIsLoading] = Loader;

    let [Data, SetData] = useState(Datas);

    return (
        <motion.section
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            transition={{ duration: 0.8 }}
            className="py-20 bg-gray-900"
        >
            {/* Section Title */}
            <div className="max-w-[1920px] mx-auto px-4 sm:px-6 lg:px-8">
                <motion.h2
                    initial={{ y: 50, opacity: 0 }}
                    whileInView={{ y: 0, opacity: 1 }}
                    viewport={{ once: false }}
                    transition={{ duration: 0.5 }}
                    className="text-center text-3xl sm:text-4xl lg:text-5xl font-bold text-white mb-14"
                >
                    {Data.Blog_Sections.Title}
                    <span className="block mt-4 h-1 bg-gradient-to-r from-green-500 to-blue-500 w-24 mx-auto rounded-full" />
                </motion.h2>

                {/* Blog Grid */}
                <motion.div
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true }}
                    variants={{
                        hidden: { opacity: 0 },
                        visible: {
                            opacity: 1,
                            transition: {
                                staggerChildren: 0.2
                            }
                        }
                    }}
                    className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8"
                >
                    {Data.Blogs.sort((a, b) => {
                        const dateA = new Date(a.Date);
                        const dateB = new Date(b.Date);
                        return dateB - dateA; // Descending order (newest first)
                    }).slice(0, 6).map((post, index) => (
                        <motion.div
                            onClick={() => { setSelected_Blog(post); Navigate("/Blog/BlogDetails"); window.scrollTo(0, 0); setIsLoading(true); }}
                            key={index}
                            variants={{
                                hidden: { opacity: 0, y: 30 },
                                visible: { opacity: 1, y: 0 }
                            }}
                            style={{ backgroundColor: `${post.Color}20` }}
                            transition={{ duration: 0.6 }}
                            className="rounded-3xl overflow-hidden shadow-lg p-2"
                            whileHover={{ scale: 1.03 }}
                        >
                            {/* Image */}
                            <img
                                src={post.Image}
                                alt={post.Title}
                                className="w-full h-72 object-cover rounded-3xl"
                            />

                            {/* Content */}
                            <div
                                className="p-6"

                            >
                                {/* Date */}
                                <p className="text-sm text-gray-400 mb-2">{post.Date}</p>

                                {/* Title */}
                                <h3 className="text-xl font-semibold text-white mb-4">
                                    {post.Title}
                                </h3>

                                {/* Description */}
                                <p className="text-gray-400">{post.Mini_Paragraph}</p>
                                <button
                                    onClick={() => { setSelected_Blog(post); Navigate("/Blog/BlogDetails"); window.scrollTo(0, 0); setIsLoading(true); }}
                                    className="mt-5 ms-1 flex text-md items-center gap-2 text-green-500 hover:text-green-600 transition-colors duration-200"
                                    whileHover={{ scale: 1.05 }}
                                    whileTap={{ scale: 0.95 }}
                                >
                                    Learn More
                                    <motion.svg
                                        animate={{ x: [0, 5, 0] }}
                                        transition={{
                                            duration: 1.5,
                                            repeat: Infinity,
                                            ease: 'linear'
                                        }}
                                        className="w-4 h-4"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth={2}
                                            d="M17 8l4 4m0 0l-4 4m4-4H3"
                                        />
                                    </motion.svg>
                                </button>
                            </div>
                        </motion.div>
                    ))}
                </motion.div>
                {Data.Blogs.length > 6 && <motion.div
                    initial={{ y: 50, opacity: 0 }}
                    whileInView={{ y: 0, opacity: 1 }}
                    viewport={{ once: false }}
                    transition={{ duration: 0.6, delay: 0.4 }}
                    className="text-center mt-20"
                >
                    <motion.button
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}
                        onClick={() => {Navigate("/Blog"); window.scrollTo(0, 0); setIsLoading(true); }}
                        className="bg-gradient-to-r from-green-600 to-blue-600 text-white py-3 px-10 rounded-3xl font-semibold"
                    >
                        More Blogs
                    </motion.button>
                </motion.div>}

            </div>
        </motion.section>
    );
};

export default Blog_Section;