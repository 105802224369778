// Our_Product_Applicability_Section


import React, { useState } from 'react';
import { motion } from 'framer-motion';
import Datas from '../../../Json_Files/All_Section_Details.json'
// import { Swiper, SwiperSlide } from 'swiper/react';
// import 'swiper/css';
// import 'swiper/css/navigation';
// import 'swiper/css/pagination';
// import { Navigation, Pagination, Autoplay } from 'swiper/modules';

const Our_Product_Applicability_Section = () => {

  let [Data,SetData] = useState(Datas.Our_Product_Applicability_Section);


  return (
    <motion.section
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      viewport={{ once: true }}
      transition={{ duration: 0.8 }}
      className="py-20 bg-gray-900"
    >
      {/* Section Title */}
      <div className="max-w-[1920px] mx-auto px-4 sm:px-6 lg:px-8">
        
        {/* Section Title */}
        <motion.h2
          initial={{ y: 50, opacity: 0 }}
          whileInView={{ y: 0, opacity: 1 }}
          viewport={{ once: false }}
          transition={{ duration: 0.5 }}
          className="text-center text-3xl sm:text-4xl lg:text-5xl font-bold text-white mb-12"
        >
          {Data.Title}
          <span className="block mt-4 h-1 bg-gradient-to-r from-green-500 to-blue-500 w-24 mx-auto rounded-full" />
        </motion.h2>

        {/* Applicability Grid */}
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          variants={{
            hidden: { opacity: 0 },
            visible: {
              opacity: 1,
              transition: {
                staggerChildren: 0.2
              }
            }
          }}
          className="grid xxs:grid-cols-2 xs:grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 gap-8"
        >
          {Data.Card_Data.map((item, index) => (
            <motion.div
              key={index}
              variants={{
                hidden: { opacity: 0, y: 30 },
                visible: { opacity: 1, y: 0 }
              }}
              transition={{ duration: 0.6 }}
              className={`rounded-3xl p-6 sm:p-8 backdrop-blur-lg text-left shadow-lg`}
              style={{ backgroundColor: `${item.Color}20` }}
              whileHover={{ scale: 1.05 }}
            >
              {/* Main Icon */}
              <div className='flex items-center justify-center'>
                <div
                  className={`flex items-center justify-center w-16 h-16 rounded-full mb-4`}
                  style={{ backgroundColor: item.Color }}
                >
                  <i
                    className={`${item.Icon} text-3xl text-white`}
                  ></i>
                </div>
              </div>

              {/* Title */}
              <h3 className="text-xl font-semibold text-center text-white">
                {item.Title}
              </h3>
            </motion.div>
          ))}
        </motion.div>


      </div>
    </motion.section>

  );
};

export default Our_Product_Applicability_Section;
