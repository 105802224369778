import React from 'react'
import Navbar from './Navbar/Navbar'
import Footer from './Footer/Footer'
import Blog_Section_Main from './All_Sections/Blog_Open_Page/Blog_Section_Main'
import Helmet_Jsx from '../Helmet_Jsx'
import Seo_Data from '../Json_Files/SEO_Meta_Keyword.json'


const Blog_Page = () => {
  return (
    <div>
      <Helmet_Jsx All={Seo_Data.Blog}></Helmet_Jsx>
      <Navbar></Navbar>

      <Blog_Section_Main></Blog_Section_Main>

      <Footer></Footer>
    </div>
  )
}

export default Blog_Page
